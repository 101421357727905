import React from "react";

function Footer() {
    return (
        <footer>
            <h3>Curriculum Vitae</h3>
            <p>All rights reserved</p>
        </footer>
    );
}

export default Footer;
