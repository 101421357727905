import React from "react";
import ProfilePicture from "../components/ProfilePicture";
import "./home.css";

function Home() {
    return (
        <div className="home">
            <ProfilePicture />
            <div className="welcome">
                <h2>Full-stack Developer</h2>
                <p>Javascript • Typescript • React • Node.js • Java</p>
            </div>
        </div>
    );
}

export default Home;
