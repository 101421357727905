import React from "react";
import "./about.css";

function About() {
    return (
        <div className="about">
            <h2>About</h2>
            <div className="text-box">
                <p>
                    Fluent in English and proficient with frontend web
                    development technologies such as Javascript, Typescript,
                    React, Redux, HTML, CSS, and version control systems,
                    especially Git/GitLab. Habituated to building and
                    maintaining scalable applications in agile environments,
                    following best programming practices, and committed to my
                    performance and that of the team. Communication is one of my
                    main characteristics. Graduated in Business Administration
                    and Systems Analysis and Development.
                </p>
            </div>
        </div>
    );
}

export default About;
