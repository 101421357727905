import { Link, useMatch, useResolvedPath } from "react-router-dom";
import { useState } from "react";
import "./navbar.css"; // Make sure to create and import a CSS file for styling

export default function Navbar() {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <nav className="nav">
            <Link to="/" className="site-title">
                João Barros
            </Link>
            <button className="hamburger" onClick={toggleMenu}>
                &#9776;
            </button>
            <ul className={`nav-links ${isOpen ? "open" : ""}`}>
                <CustomLink to="/about">About</CustomLink>
                <CustomLink to="/experience">Experience</CustomLink>
                <CustomLink to="/education">Education</CustomLink>
            </ul>
        </nav>
    );

    function CustomLink({ to, children, ...props }) {
        const resolvedPath = useResolvedPath(to);
        const isActive = useMatch({ path: resolvedPath.pathname, end: true });

        return (
            <li className={isActive ? "active" : ""}>
                <Link to={to} {...props}>
                    {children}
                </Link>
            </li>
        );
    }
}
